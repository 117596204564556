import _ from "lodash";
import moment from "moment";
import copy from "clipboard-copy";
import { DurationOptions, USER_ROLE } from "constants/index";
import { IsImpersonating } from "helpers/api/apiCore";
import { arrayToObject } from "utils";
declare global {
  interface Window {
    runConfig: any;
    OneSignal: any;
  }
}

export const TOUR_LINK = "https://tour.360linked.com/tour/";

export const AUTH_SESSION_KEY = "linkedWebApplication";
export const PERMISSIONS_KEY = "user-permissions";
export const INTEGRATIONS_KEY = "app-integrations";
export const CALENDAR_PERMISSIONS_KEY = "calendar-permissions";
export const DEV_API_BASE_URL_KEY = "dev-api-base-url";
export const CLIENT_FILTER_KEY = "filter-client";

export const { APIBaseURL: API_BASE_URL = "https://stapi.360linked.com" } =
  window.runConfig || {};

export const ICON_LIST = [
  {
    name: "Card",
    icon: "uil-table",
  },
  {
    name: "Table",
    icon: "uil uil-list-ul",
  },
];

export const IMAGE_EXT = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "webp",
  "svg",
  "ico",
];

export const VIDEO_EXT = [
  "mp4",
  "webm",
  "ogg",
  "mkv",
  "mov",
  "avi",
  "wmv",
  "flv",
  "mpeg",
  "mpg",
  "m4v",
  "3gp",
  "m2ts",
  "ts",
  "vob",
];

export const CLIENT_TYPES = ["Personal", "Commercial"];
export const CLIENT_FILTERS = [
  { label: "Personal", value: "PERSONAL" },
  { label: "Commercial", value: "COMMERCIAL" },
];

export const durationOptions: any = {
  ...arrayToObject(DurationOptions, "value", "label"),
  "0": "-",
};

export const FormatDate = (date: any, format: string = "ll") => {
  return moment(date).format(format);
};

export const OpenLink = (link: string) => {
  return window.open(link, "_blank");
};

export const IsJSON = (data: any) => {
  try {
    JSON.parse(data);
    return true;
  } catch (error) {
    return false;
  }
};

export const RemoveFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const SetLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
  return true;
};

export const ReadLocalStorage = (key: string) => {
  try {
    let data = localStorage.getItem(key);
    if (data && IsJSON(data)) {
      data = JSON.parse(data);
    }
    return data;
  } catch (error) {
    console.log(error, "");
    return "";
  }
};

export const SortMessagesByDay = (messages: any[]) => {
  try {
    const groupedMessages = _.chain(messages)
      .filter((element) => !element.hasOwnProperty("tmid"))
      .groupBy((element) => {
        const messageDate = moment(element.ts.$date);

        if (messageDate.isSame(moment(), "day")) {
          return "Today";
        } else if (messageDate.isSame(moment().subtract(1, "day"), "day")) {
          return "Yesterday";
        } else {
          return messageDate.format("DD/MM/YY");
        }
      })
      .map((messages, day) => ({
        id: _.uniqueId(),
        day: day,
        messages: messages,
      }))
      .value();

    return groupedMessages;
  } catch (error) {
    console.log("error", error);
    return [];
  }
};

export const getPlayerId = async () => {
  try {
    if (window.OneSignal) {
      return await window.OneSignal.getUserId(async function (userId: any) {
        return userId;
      });
    }
  } catch (error) {
    console.log("one signal error getting user id");
  }
  return "na";
};

export const timeFromNow = (date: any) => {
  try {
    const timeAgo = moment(date).fromNow();
    return timeAgo;
  } catch (error) {
    console.log(error);
  }
  return "NA";
};

export const ValueFromSearchString = (searchString = "", key = "") => {
  try {
    const searchParams = new URLSearchParams(searchString);
    return searchParams.get(key);
  } catch (error) {
    console.log("error", error);
  }
};

export const HandleCopyClick = async (textToCopy: string) => {
  try {
    await copy(textToCopy);
    console.log("Text copied to clipboard");
  } catch (err) {
    console.error("Unable to copy text to clipboard", err);
  }
};

export const ReduceString = (text: any = "") => {
  try {
    return text.toLowerCase().trim().replace(/ /g, "");
  } catch (error) {
    console.log("error", error);
  }
  return "";
};

export const GeneratePassword = (): string => {
  const length = 12;
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let password = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset.charAt(randomIndex);
  }

  return password;
};

export const RenderActivityIcon = (type: any = "") => {
  if (type === "Info") {
    return `uil-check me-1`;
  }
  if (type === "Notice") {
    return `uil-exclamation-triangle me-1`;
  }
  return `uil-info-circle me-1`;
};

export const ValueFromUserData = (key: string) => {
  try {
    const userdata: any = ReadLocalStorage(AUTH_SESSION_KEY);
    if (typeof userdata === "object") return userdata[key];
  } catch (error) {
    console.log("error", error);
  }
  return null;
};

export const IsUserAllowedTo = (permissionKey: string | undefined) => {
  try {
    // exception:- if user is Admin and added by Super Admin, then allowed eveything or user is itself Super Admin
    let userdata: any = null;
    if (IsImpersonating()) {
      userdata = sessionStorage.getItem(AUTH_SESSION_KEY);
      if (userdata && IsJSON(userdata)) {
        userdata = JSON.parse(userdata);
      }
    } else {
      userdata = ReadLocalStorage(AUTH_SESSION_KEY);
    }
    if (
      userdata &&
      ((userdata.RoleName === USER_ROLE.ADMIN && userdata.AddedBy === 1) ||
        userdata.RoleName === USER_ROLE.SUPERADMIN)
    ) {
      return true;
    }
    //
    const permissions: any = ReadLocalStorage(PERMISSIONS_KEY);
    if (permissions && permissions.data && permissions.data.Permissions) {
      const found: any = permissions.data.Permissions.find(
        (el: any) => el.PermissionKey === permissionKey
      );
      if (found) {
        return found.HasPermission;
      }
    }
    return false;
  } catch (error) {
    console.log("error", error);
  }
};

export const IsUserAllowedCalendarPermission = (
  permissionKey: string | undefined
) => {
  try {
    // exception:- if user is Admin and added by Super Admin, then allowed eveything or user is itself Super Admin
    let userdata: any = null;
    if (IsImpersonating()) {
      userdata = sessionStorage.getItem(AUTH_SESSION_KEY);
      if (userdata && IsJSON(userdata)) {
        userdata = JSON.parse(userdata);
      }
    } else {
      userdata = ReadLocalStorage(AUTH_SESSION_KEY);
    }
    //
    const permissions: any = ReadLocalStorage(CALENDAR_PERMISSIONS_KEY);
    if (permissions && permissions.data) {
      const found: any = permissions.data.find(
        (el: any) => el.PermissionKey === permissionKey
      );
      return !!found;
    }
    return false;
  } catch (error) {
    console.log("error", error);
  }
};

export const ExtractUrlsFromString = (str: string, lower: boolean = false) => {
  const regexp =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()'@:%_\+.~#?!&//=]*)/gi;
  const bracketsRegexp = /[()]/g;

  if (typeof str !== "string") {
    throw new TypeError(
      `The str argument should be a string, got ${typeof str}`
    );
  }

  if (str) {
    let urls = str.match(regexp);
    if (urls) {
      return lower
        ? urls.map((item) => item.toLowerCase().replace(bracketsRegexp, ""))
        : urls.map((item) => item.replace(bracketsRegexp, ""));
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const UpdateStringToIncludeLinks = (str: string, urls: string[]) => {
  try {
    // Escape special characters in the URL for regex
    const escapedUrls = urls.map((url) =>
      url.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
    );
    // Create a regex pattern to match the URLs
    const pattern = new RegExp(`(${escapedUrls.join("|")})`, "g");
    // Replace the URLs in the string with the desired format
    const updatedStr = str.replace(
      pattern,
      '<a href="$1" target="_blank">$1</a>'
    );

    return updatedStr;
  } catch (error) {
    console.log("error", error);
    return "";
  }
};

export const extractPath = (url: string) => {
  try {
    let parts = url.split("?");
    return parts[0];
  } catch (error) {
    console.log("error", error);
  }
  return url;
};

export const IsIndependentVendor = () => {
  try {
    const IsIndependent = ValueFromUserData("IsIndependent");
    const RoleName = ValueFromUserData("RoleName");
    return RoleName === USER_ROLE.VENDOR && IsIndependent;
  } catch (error) {
    console.log("error", error);
  }
  return false;
};

export const IsIndependentUser = () => {
  try {
    const IsIndependent = ValueFromUserData("IsIndependent");
    const RoleName = ValueFromUserData("RoleName");
    return RoleName === USER_ROLE.USER && IsIndependent;
  } catch (error) {
    console.log("error", error);
  }
  return false;
};

export const IsIndependentLogin = () => {
  try {
    const IsIndependent = ValueFromUserData("IsIndependent");
    return !!IsIndependent;
  } catch (error) {
    console.log("error", error);
  }
  return false;
};

export const ClientFilterId = () => {
  try {
    const clientFilterId: any = ReadLocalStorage(CLIENT_FILTER_KEY);
    if (clientFilterId) return clientFilterId;
  } catch (error) {
    console.log("error", error);
  }
  return false;
};

export const IsSuperAdminImpersonatingAdmin = () => {
  try {
    const userdata: any = ReadLocalStorage(AUTH_SESSION_KEY);
    if (
      IsImpersonating() &&
      userdata &&
      userdata.RoleName === USER_ROLE.SUPERADMIN
    ) {
      let impersonatedUserdata: any = sessionStorage.getItem(AUTH_SESSION_KEY);
      if (impersonatedUserdata && IsJSON(impersonatedUserdata)) {
        impersonatedUserdata = JSON.parse(impersonatedUserdata);
        if (impersonatedUserdata.RoleName === USER_ROLE.ADMIN) {
          return true;
        }
      }
    }
  } catch (error) {
    console.log("error", error);
  }
  return false;
};

export const IsSuperAdminUser = () => {
  try {
    let userdata: any = ReadLocalStorage(AUTH_SESSION_KEY);
    if (userdata && userdata.RoleName === USER_ROLE.SUPERADMIN) {
      return true;
    }
  } catch (error) {
    console.log("error", error);
  }
  return false;
};

export const IsAdminUser = () => {
  try {
    let userdata: any = ReadLocalStorage(AUTH_SESSION_KEY);
    if (userdata && userdata.RoleName === USER_ROLE.ADMIN) {
      return true;
    }
  } catch (error) {
    console.log("error", error);
  }
  return false;
};

export const IsVendorUser = () => {
  try {
    let userdata: any = ReadLocalStorage(AUTH_SESSION_KEY);
    if (userdata && userdata.RoleName === USER_ROLE.VENDOR) {
      return true;
    }
  } catch (error) {
    console.log("error", error);
  }
  return false;
};

export const prepareParamsUrl = (url = "", params: any = {}) => {
  try {
    const keys = Object.keys(params);
    let _url = `${url}?`;
    let _paramString = keys
      .map((key) => {
        return `${key}=${params[key]}`;
      })
      .join("&");
    console.log(_url);
    return `${_url}${_paramString}`;
  } catch (error) {
    console.log("error", error);
  }
  return url;
};

export const IsMemberOfAnotherTeam = () => {
  try {
    const userdata: any = ReadLocalStorage(AUTH_SESSION_KEY);
    return userdata.MemberOfAnotherTeam;
  } catch (error) {
    console.log("error", error);
  }
  return false;
};

export const PrepareLayout = (data: any) => {
  try {
    const groupedByY = _.groupBy(data, "y");
    const groupedArray = _.values(groupedByY);
    const sortedGroupedArray = groupedArray.map((group) =>
      _.sortBy(group, "x")
    );
    const result = _.sortBy(
      sortedGroupedArray,
      (group) => _.minBy(group, "x").x
    );
    const finalResult = result.map((group) => group.map((item) => item.i));
    return finalResult;
  } catch (error) {
    console.log("error", error);
  }
  return null;
};

export const GetLocationCoordinates = async () => {
  return new Promise((resolve, reject) => {
    try {
      // Check if the browser supports geolocation
      if ("geolocation" in navigator) {
        // Get the current position
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Retrieve latitude and longitude
            let latitude = position.coords.latitude;
            let longitude = position.coords.longitude;

            // Resolve with latitude and longitude
            resolve({ latitude, longitude });
          },
          (error) => {
            // Handle errors
            switch (error.code) {
              case error.PERMISSION_DENIED:
                reject("User denied the request for Geolocation.");
                break;
              case error.POSITION_UNAVAILABLE:
                reject("Location information is unavailable.");
                break;
              case error.TIMEOUT:
                reject("The request to get user location timed out.");
                break;
              default:
                reject("An unknown error occurred.");
                break;
            }
          }
        );
      } else {
        reject("Geolocation is not supported by this browser.");
      }
    } catch (error) {
      reject(
        (error as Error).message || "An error occurred while getting location."
      );
    }
  });
};

export const calendarEventResolveColor = (Status: string) => {
  let color;
  if (Status === "OPEN") {
    color = "#33e879";
  } else if (Status === "CLOSE") {
    color = "#eb4963";
  } else {
    color = "#4dbcd5";
  }
  return color;
};

export const calendarEventResolveAsideBackgroundColor = (Status: string) => {
  let color;
  if (Status === "OPEN") {
    color = "#ecfded";
  } else if (Status === "CLOSE") {
    color = "#fbe9ff";
  } else {
    color = "#fef4e2";
  }
  return color;
};

export const durationFromSeconds = (seconds: any) => {
  try {
    const milliseconds = seconds * 1000;
    return `${moment.utc(milliseconds).format("hh")}h ${moment
      .utc(milliseconds)
      .format("mm")}m ${moment.utc(milliseconds).format("ss")}s`;
  } catch (error) {
    console.log("error", error);
  }
  return "NA";
};

export const FormatCurrency = (amount: any = "") => {
  return `$${amount}`;
};

export const createUrlWithParams = (url: string, params: any = {}) => {
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");
  return `${url}?${queryString}`;
};
